import Vue from "vue";
//@ts-ignore
import NotificationHeader from "@/views/Admin/Notifications/Dependencies/Header/index.vue";

export default Vue.extend({
	name: "Notifications",
	model: {},
	props: {},
	components: { NotificationHeader },
	data: () => ({}),
	created() {
		this.$nextTick(async () => {
			//console.log(`${this.$options.name}::created`);
		});
	},
	async mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
