import Vue from "vue";
import TitleWelcome from "@/components/Header/TitleWelcome.vue";
import BreadCrumbs from "@/components/Content/BreadCrumbs.vue";
import { last } from "lodash";

export default Vue.extend({
	name: "NotificationHeader",
	model: {},
	props: {},
	components: {
		TitleWelcome,
		BreadCrumbs,
	},
	data: () => ({}),
	created() {
		this.$nextTick(async () => {
			//console.log(`${this.$options.name}::created`);
		});
	},
	async mounted() {},
	computed: {
		getTitle() {
			let self = this;
			const result: any = last(self.$route.matched);
			return result.meta.breadcrumb;
		},
	},
	methods: {},
	watch: {},
});
